import React from "react";
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import Layout from "/src/layouts/layout";
import Video from "/src/components/video";
import JumpstartYourCareer from "/src/components/jumpstart-your-career";
import Testimony from "/src/components/testimony";

const Professionals = ({ data: { hero, pageData } }) => {


return (

<Layout hero={hero}>
    <div className="container mx-auto mb-16">
        <div className="md:flex justify-between mb-16">

            <div className="flex-1 px-4 mr-4 sm:mr-8 mb-4 self-start">
                <Img className="object-cover object-center w-full h-full block" fluid={pageData.promoImage.fluid} />
            </div>

            <div className="flex-1 px-4 mb-4 order-first">
                <h2 class="text-3xl sm:text-5xl font-bold dark-blue leading-tight tracking-tight font-aktiv mb-4">Come Work With Us</h2>
                <div className="soft-gray leading-relaxed nested-html"
                    dangerouslySetInnerHTML={{ __html: pageData.promoOneText.childMarkdownRemark.html }}
                />                    

            </div>

               
    </div>
</div>

{pageData.promoTwoText && pageData.promoThreeText && 
<div style={{backgroundColor: "#f5f5f5"}} className="border-t border-b">
    <div className="container py-8 mx-auto mb-16">

        {pageData.promoTwoText &&
        <div className="md:flex items-center justify-between mb-16">
            <div className="flex-1 px-4 mb-4 order-first">
                <h2 class="text-3xl sm:text-5xl font-bold dark-blue leading-tight tracking-tight font-aktiv mb-4">Students</h2>
                <div className="soft-gray leading-relaxed nested-html"
                    dangerouslySetInnerHTML={{ __html: pageData.promoTwoText.childMarkdownRemark.html }}
                />                    
                <div className="inline-block uppercase border-b-2 text-xs pb-1 border-mane-yellow text-gray-800 font-bold font-aktivcd">
                    <a href="#">Find an Opportunity</a>
                </div>
            </div>

            <div className="flex-1 px-4 text-right sm:mb-0 self-start">
                <Img className="object-cover object-center ml-auto sm:w-4/5 h-full block" fluid={pageData.promoTwoImage.fluid} />
            </div>
        </div>
        }

        {pageData.promoThreeText &&
        <div className="md:flex items-center justify-between mb-16">
            <div className="flex-1 px-4 mb-4">
                <h2 class="text-3xl sm:text-5xl font-bold dark-blue leading-tight tracking-tight font-aktiv mb-4">Recent Grads</h2>
                {pageData.promoThreeText && 
                <div className="soft-gray leading-relaxed nested-html"
                    dangerouslySetInnerHTML={{ __html: pageData.promoThreeText.childMarkdownRemark.html }}
                />                    
                }
                <div className="inline-block uppercase border-b-2 text-xs pb-1 border-mane-yellow text-gray-800 font-bold font-aktivcd">
                    <a href="#">Find an Opportunity</a>
                </div>
            </div>
            <div className="flex-1 px-4 sm:mb-0">
                <Img className="object-cover object-center ml-auto h-full block sm:w-4/5" fluid={pageData.promoThreeImage.fluid} />
            </div>
        </div>
        }

    </div>
</div>
}


    {/* Testimonies */}

    <div className="border-t border-b mb-16" style={{backgroundColor: "#f5f5f5"}}>
        {pageData.testimonies.map((testimony, i) => <Testimony testimony={testimony} count={i} /> )}
    </div>

    <JumpstartYourCareer />


</Layout>

)};

export const query = graphql`
    query Professionals {
      hero: contentfulCareersSubPage(slug: {eq: "professionals"}) {
        heroBackgroundImage {
          resize(width: 1440) {
           src
          }
        }
        heroHeading
        heroText: heroSubtext
      }

      pageData:contentfulCareersSubPage (slug: {eq: "professionals"}) {
        promoOneText {
           childMarkdownRemark {
                   html
             }
        }
        promoImage { 
            fluid {
                ...GatsbyContentfulFluid_withWebp_noBase64
            }
        }
        promoTwoImage {
            fluid {
                ...GatsbyContentfulFluid_withWebp_noBase64
            }
        }
        promoTwoText {
          childMarkdownRemark {
            html
          }
       }  
       promoThreeText {
        childMarkdownRemark {
            html
        }
       }
       promoThreeImage {
        fluid  {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
       }
       testimonies {
         name
         position
         testimony {
          testimony
        }
         picture {
            fluid {
                ...GatsbyContentfulFluid_withWebp_noBase64
            }
         }
      }
    }
}
`;


export default Professionals;
